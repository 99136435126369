import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'homeView',
    redirect:'/page',
    component: () => import(/* webpackChunkName: "about" */ '../views/homeView.vue'),
    children:[
      {
        // 首页
        path: '/firstpage',
        redirect:'/page',
        // name: 'firstpage',
        component: () => import(/* webpackChunkName: "about" */ '../views/firstpage.vue'),
        children:[
          // 视频转文字
          {
            path: '/page',
            name: 'page',
            component: () => import(/* webpackChunkName: "about" */ '../views/page/page.vue'),
          },
          {
            path: '/pages',
            name: 'pages',
            component: () => import(/* webpackChunkName: "about" */ '../views/page/pages.vue'),
          },
          {
            path: '/music',
            name: 'music',
            component: () => import(/* webpackChunkName: "about" */ '../views/page/music.vue'),
          },
          {
            path: '/picture',
            name: 'picture',
            component: () => import(/* webpackChunkName: "about" */ '../views/page/picture.vue'),
          },
          {
            path: '/write',
            name: 'write',
            component: () => import(/* webpackChunkName: "about" */ '../views/page/write.vue'),
          },
          {
            path: '/detailpage',
            name: 'detailpage',
            component: () => import(/* webpackChunkName: "about" */ '../views/page/detailpage.vue'),
          },
          {
            path: '/pictureDetails',
            name: 'pictureDetails',
            component: () => import(/* webpackChunkName: "about" */ '../views/page/pictureDetails.vue'),
          },
        ]
      },
      {
        // vip页面
        path: '/vip',
        name: 'vip',
        component: () => import(/* webpackChunkName: "about" */ '../views/vip.vue'),
      },
      {
        // 字幕管理
        path: '/subtitle',
        name: 'subtitle',
        component: () => import(/* webpackChunkName: "about" */ '../views/subtitle.vue'),
      }, 
      {
        // 字幕管理编辑页面
        path: '/subtitles',
        name: 'subtitles',
        component: () => import(/* webpackChunkName: "about" */ '../views/page/subtitles.vue'),
      }, 
      {
        // 教程帮助详情页
        path: '/studays',
        name: 'studays',
        component: () => import(/* webpackChunkName: "about" */ '../views/page/studays.vue'),
      },
      {
        //教程帮助
        path: '/studay',
        name: 'studay',
        component: () => import(/* webpackChunkName: "about" */ '../views/studay.vue'),
      },
      {
        //App下载页面
        path: '/appdownload',
        name: 'appdownload',
        component: () => import(/* webpackChunkName: "about" */ '../views/appdownload.vue'),
      },
      {
        //App下载页面
        path: '/vips',
        name: 'vips',
        component: () => import(/* webpackChunkName: "about" */ '../views/vips.vue'),
      }
    ]
  },
  {
    path: '/about', 
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutView.vue'),
  },
  {
    path: '/deng', 
    name: '登陆',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/page/deng.vue'),
  },
  {
    path: '/login', 
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
  },
]




const router = new VueRouter({
  mode: 'history',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,

  // 添加以下代码
  scrollBehavior(to,from,savedPosition){
    if(savedPosition){
      return savedPosition;
    }else{
      return {x:0,y:0}
    }
  },
})

router.beforeEach((to, from, next) => {
  if (_hmt) {
      if (to.path) {
          _hmt.push(['_trackPageview', '/#' + to.fullPath]);
      }
  }
  next();
});


// router.beforeEach((to, from, next) => {
//   // 到登陆页面 直接放行    
//    if(to.path ==='/deng'|| to.path ==='/page') return next()
//   // JSON.parse(localStorage.) 赋值token
//     const tokenStr =JSON.parse(localStorage.getItem('token'))
//     console.log(tokenStr);  
//   //  判断是否有token
//     if(!tokenStr){
//       return next('/deng')
//     }else{
//       next()
//     }

// })
  



export default router
