import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from "axios";
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import clipboard from 'clipboard';
Vue.prototype.clipboard = clipboard
import QRCode from "qrcode"
import md5 from 'js-md5';//使用md5进行加密


import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);


Vue.prototype.$md5 = md5;
Vue.use(QRCode)

var _hmt = _hmt || [];
window._hmt = _hmt; // 将_hmt挂载到window下
 (function () {
       var hm = document.createElement("script");
       hm.src = "https://hm.baidu.com/hm.js?129770a98e62c55aafa6bc922d8c7e69";
       var s = document.getElementsByTagName("script")[0];
       s.parentNode.insertBefore(hm, s);
  })();

// import Video from 'video.js'
// import 'video.js/dist/video-js.css'
 
// Vue.prototype.$video = Video

Vue.use(ElementUI);
Vue.use(Vant);
// Vue.use(Swiper);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  axios,  
  Vant,
  // ElementUI,
  render: h => h(App),
  // mounted(){
	// 	document.dispathEvent(new Event('render-event'))
	// },
}).$mount('#app')


// https://open.weixin.qq.com/connect/qrconnect?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE