<template>
  <div id="app">
    <nav>
     <router-view/>
    </nav>
  </div>
</template>
<script>
export default {
   data() {
    return {
      
    }
   },
   
}
</script>

<style lang="scss">
*{
  padding: 0;
  margin: 0;
  // cursor:pointer;
}

</style>
